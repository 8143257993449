<template>
    <div class="users-page">
        <TopHeader />

        <!-- Dashboard -->
        <main class="container-fluid main-content mt64">
            <div class="page-header">
                <h1 class="page-title title-xl mb8">Usuários</h1>
                <div class="search">
                    <TextField v-model="query.filter" @keydown.enter.native="filterUsers()" :data="{}" placeholder="Nome, email ou id..." class="mr8" />
                    <SelectField v-model="query.subscriptions" :options="['true', 'false']" @update:value="filterUsers()" class="mr8" />
                    <SelectField v-model="query.orderby" :options="['id', 'respondents', 'forms']" @update:value="filterUsers()" class="mr8" />
                    <TextField v-model="query.product" @keydown.enter.native="filterUsers()" :data="{}" placeholder="Product" class="mr8" />
                    <TextField v-model="query.price" @keydown.enter.native="filterUsers()" :data="{}" placeholder="Price" class="mr8" />
                    <TextField v-model="query.perpage" @keydown.enter.native="filterUsers()" :data="{}" placeholder="25/page" />
                    <Btn v-if="searchQuery" @click.native="clearFilter" layout="remove"></Btn>
                </div>
                <!-- <p class="page-description title-sm light">Existem 158 usuários, e 356 formulários.</p> -->
            </div>

            <section v-if="users" class="users-table mt32">
                <TableView :headers="table.headers" >
                    <tr v-for="user in users" :key="user.id">
                        <td class="col">{{user.id}}</td>
                        <td class="col"><router-link :to="{ name: 'UserForms', params: { user_id: user.id, user: user }}">{{user.name}}</router-link></td>
                        <td class="col">{{user.email}}</td>
                        <td class="col"><a target="_blank" :href="`https://api.whatsapp.com/send?phone=${user.phone}&text=Oi%20${user.name}, `">{{user.phone | VMask('+## (##) #####-####')}}</a></td>
                        <td class="col">{{user.onboarding.first_day_clicks}}</td>
                        <td class="col">{{user.forms_count}}</td>
                        <td class="col">{{user.respondents_count}}</td>
                        <td class="col">{{user.created_at}}</td>
                        <td class="col">{{user.last_login_at}}</td>
                        <td class="col">
                            <a v-if="user.subscriptions" :href="'https://dashboard.stripe.com/customers/' + user.subscriptions.gateway_customer_id">{{user.plan}}</a>
                            <span v-else>{{user.plan}}</span>
                        </td>
                        <td class="col">{{user.role}}</td>
                        <td class="col"><Btn v-if="user.plan_level > 1" @click.native="openCancelModal(user)" size="xs" type="danger">Cancel</Btn></td>
                    </tr>
                </TableView>

                <Btn v-if="users.length >= 25" @click.native="loadMore" type="secondary" class="mt16 mb32">Carregar mais...</Btn>
            </section>

            <!-- Cancel sub modal -->
            <Modal v-if="cancelModal.user" :active="cancelModal.open" @modal-close="closeCancelModal" title="Cancelar assinatura">
                <h2>{{cancelModal.user.name}}</h2>                
                <p class="mt8">{{cancelModal.user.email}}</p>                
                <p class="mt8 mb16">Plano atual: {{cancelModal.user.plan}}</p>                
                <div class="actions">
                    <Btn @click.native="cancelSubscriptionConfirm(cancelModal.user)" :loading="working.cancelSubscription" type="danger">Cancelar assinatura</Btn>
                </div>
            </Modal>
        </main>


    </div>
</template>

<script>
import SelectField from '../../components/form/SelectField.vue'
import Modal from '../../components/Modal.vue'
import Icon from '../../components/Icon.vue'
import Btn from '../../components/form/Btn.vue'
import TextField from '../../components/form/TextField.vue'
    import TableView from '../../components/TableView.vue'
    import TopHeader from '../../components/TopHeader.vue'
    import { UsersMixin } from '@/mixins/UsersMixin'
    export default {
    components: { TopHeader, TableView, TextField, Btn, Icon, Modal, SelectField },
    mixins: [UsersMixin],

    data() {
        return {
            users: null,
            searchQuery: null,
            query: {
                filter: null,
                subscriptions: null,
                product: null,
                price: null,
                orderby: null,
                perpage: null,
            },
            page: 1,
            table: {
                rows: this.users,
                headers: ["ID", "Nome", "E-mail", "Telefone", "FD Clicks", "Forms","Respostas", "Criado", "Último login", "Plano", "Role", "Ação"]
            },
            cancelModal: {
                open: false,
                user: null
            },
            working: {
                cancelSubscription: false
            }
            
        }
    },

    mounted () {
        if(this.$route.query){
            this.query = {...this.query, ...this.$route.query}
            this.filterUsers()
        } 
        else this.getAllUsers()
    },

    watch: {
        searchQuery(newValue, oldValue) {
            this.$router.push({query: this.query})
        }
    },

    methods: {
        getAllUsers(){
            this.getUsers()
            .then(response => {
                this.users = response
            })            
        },

        closeCancelModal(){
            this.cancelModal.open = false
            this.cancelModal.user = null
        },
        openCancelModal(user){
            this.cancelModal.open = true
            this.cancelModal.user = user
        },

        cancelSubscriptionConfirm(user){
            this.working.cancelSubscription = true
            this.cancelSubscription(user)
            .then(response => {
                this.working.cancelSubscription = false
                this.cancelModal.open = false
            })
        },

        totalAnswers(forms) {
            let total = 0
            forms.forEach(form => {
                total = total + form.respondents_count
            })

            return total
            
        },

        clearFilter(){
            this.filter = null
            this.$router.replace({'query': null})            
            this.filterUsers()
        },

        filterUsers(){
            this.page = 1
            this.searchQuery = this.buildSearchQuery()  
            // this.$router.push({query: this.query})         
            this.getUsers(this.searchQuery, this.page, this.orderby)
                .then(response => {                    
                    this.users = response
                })
        },



        buildSearchQuery(){
            let query = new URLSearchParams()
            if(this.query.filter) query.append("filter", this.query.filter)
            if(this.query.orderby) query.append("orderby", this.query.orderby)
            if(this.query.subscriptions) query.append("subscriptions", this.query.subscriptions)
            if(this.query.product) query.append("product", this.query.product)
            if(this.query.price) query.append("price", this.query.price)
            if(this.query.perpage) query.append("perpage", this.query.perpage)
            
            return query.toString()
        },



        loadMore(){            
            this.page++
            this.getUsers(this.searchQuery, this.page)
            .then(response => {
                this.users = this.users.concat(response)
            })

        }
    },
        
    }
</script>

<style lang="sass">

.users-page

    .page-header
        display: flex
        justify-content: space-between

        .search
            display: flex
            min-width: 50%
            align-items: center


</style>